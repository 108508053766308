body {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  font-family: Arial, sans-serif;
}

.column {
  text-align: center;
  margin: 10px;
  flex: 1 1 300px;
}

.counter {
  font-size: 2em;
  margin: 20px 0;
}

.button {
  font-size: 2em;
  padding: 10px 20px;
  cursor: pointer;
}

.button.increment {
  background-color: lightgreen;
}

.button.decrement {
  background-color: lightcoral;
  font-size: 1em; /* Smaller size */
}

.small-button {
  font-size: 1em;
  padding: 5px 10px;
  cursor: pointer;
}

.reset-button {
  font-size: 1em;
  padding: 5px 20px;
  cursor: pointer;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.reset-button span {
  font-size: 0.8em;
  margin-left: 5px;
}

.counter-input {
  font-size: 2em;
  text-align: center;
  width: 80px; /* Adjusted width */
  margin-bottom: 10px; /* Margin between reset and counter */
}

.last-saved {
  font-size: 0.8em;
  color: gray;
}

.last-saved-score {
  font-size: 1em;
  font-weight: bold;
}

.rina {
  color: purple;
}

.cj {
  color: darkblue;
}

.rentia {
  color: pink;
}

.margin-top {
  margin-top: 10px;
}

.margin-bottom {
  margin-bottom: 10px; /* Margin between +5 +10 buttons and last saved score */
}

.margin-between {
  margin-bottom: 20px; /* Margin between reset and counter */
}

@media (max-width: 900px) {
  body {
    flex-direction: column;
    height: auto;
  }
  .column {
    flex: 1 1 100%;
    margin: 5px 0;
  }
}