.shopping-list {
    text-align: center;
    margin: 20px;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  section {
    margin-bottom: 20px;
  }
  
  h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .sync-status {
    margin-left: 10px;
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 0.8em;
    color: gray;
    display: flex;
    align-items: center;
    border: 2px solid grey; /* Default border color */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add shadow */
    transition: all 0.2s ease; /* Smooth transition */
  }
  
  .sync-status.syncing {
    background-color: #f0f0f0;
  }
  
  .sync-status.synced {
    background-color: #e0ffe0;
    border-color: green; /* Green border when synced */
  }
  
  .sync-button:active .sync-status {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Pressing effect */
    transform: translateY(2px); /* Move down slightly */
  }
  
  .icon-spin {
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  ul {
    list-style-type: none;
    padding: 0 !important;
    max-width: 1000px; /* Max width of 1000px */
    margin: 0 auto; /* Center the list */
  }
  
  li {
    margin: 3px 0; /* Further reduced margin for more compact list */
    font-size: 1.2em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; /* Take full width */
    box-sizing: border-box; /* Include padding and border in the width */
  }
  
  .item-content {
    display: flex;
    align-items: center;
    flex-grow: 1; /* Allow item content to grow */
  }
  
  .item-content-text {
    margin-right: 10px; /* Add margin between text and edit button */
    max-width: 60vw;
    overflow: scroll;
    white-space: normal; /* Allow text to wrap */
  }
  @media (max-width: 325px) {
    .item-content-text {
      max-width: 65vw;
    }
  }
  @media (max-width: 300px) {
    .item-content-text {
      max-width: 70vw;
    }
  }
  @media (max-width: 275px) {
    .item-content-text {
      max-width: 75vw;
    }
  }
  @media (max-width: 250px) {
    .item-content-text {
      max-width: 80vw;
    }
  }
  @media (max-width: 225px) {
    .item-content-text {
      max-width: 90vw;
    }
  }
  @media (max-width: 200px) {
    .item-content-text {
      max-width: 100vw;
    }
  }
  @media (max-width: 175px) {
    .item-content-text {
      max-width: 110vw;
    }
  }
  
  input[type="checkbox"] {
    margin-right: 5px; /* Reduced margin */
  }
  
  .large-checkbox {
    width: 24px;
    height: 24px;
  }
  
  .add-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  
  .add-item input {
    padding: 8px; /* Reduced padding */
    font-size: 1em;
    margin-right: 10px;
    width: 100%;
    max-width: 500px; /* Max width of 500px */
    box-sizing: border-box; /* Ensure padding is included in the width */
  }
  
  .add-item button {
    padding: 8px; /* Reduced padding */
    font-size: 1em;
    cursor: pointer;
  }
  
  .edit-container {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .edit-input {
    padding: 5px;
    font-size: 1em;
    width: 100%;
    max-width: 300px;
    box-sizing: border-box;
  }
  
  .save-button {
    position: absolute;
    right: 5px;
    background: none;
    border: none;
    color: green;
    font-size: 1.2em;
    cursor: pointer;
    padding: 0; /* Remove padding */
  }
  
  .edit-button {
    background: none;
    border: none;
    color: blue;
    font-size: 1.2em;
    cursor: pointer;
    padding: 0; /* Remove padding */
  }
  
  .strikethrough {
    text-decoration: line-through;
    color: gray;
  }
  
  .remove-button {
    background: none;
    border: none;
    color: red;
    font-size: 1.2em;
    cursor: pointer;
    padding: 0; /* Remove padding */
  }
  
  button {
    margin: 3px; /* Reduced margin */
    padding: 8px; /* Reduced padding */
    font-size: 1em;
    cursor: pointer;
  }
  
  .loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .restore-button {
    background-color: #f0f0f0;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  .sync-button {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 999; /* Below the dialog */
  }
  
  .update-dialog {
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    text-align: center;
  }
  
  .update-dialog p {
    margin: 10px 0;
  }
  
  .update-dialog button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .update-dialog button:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 600px) {
    .add-item input {
      width: 100%;
    }
  }