.hamburger-button {
  background: rgba(128, 128, 128, 0.3) !important; /* More transparent gray */
  border: none !important;
  font-size: 24px;
  cursor: pointer;
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1050; /* Ensure it is above other elements */
  color: gray; /* Gray color for the icon */
}

.colorful-button {
  background-color: #ff69b4; /* Example color */
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.colorful-button:hover {
  background-color: #ff1493; /* Darker shade for hover effect */
}

.grey-button {
  background-color: grey;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.grey-button:hover {
  background-color: darkgrey;
}

.green-button {
  background-color: green;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.green-button:hover {
  background-color: darkgreen;
}

.rainbow-text {
  background: linear-gradient(90deg, red, orange, yellow, green, blue, indigo, violet);
  -webkit-background-clip: text;
  color: transparent;
  animation: rainbow-animation 5s infinite;
}

.black-button {
  background-color: black;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px 0;
}

@keyframes rainbow-animation {
  0% { background-position: 0% 50%; }
  100% { background-position: 100% 50%; }
}

.drawer-nav {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}